<template>

<div class="bg-[#58C231] px-4">
  <nav class="container md:px-20 py-2 mx-auto md:flex md:justify-between md:items-center">
      <div class="flex items-center justify-between">
        <router-link to="/">
          <img src="./assets/Logo 150 (1).png" alt="Website logo" class="h-10 w-10 md:h-20 md:w-20">
        </router-link>
      <!-- Mobile menu button -->
      <div @click="toggleNav" class="flex md:hidden">
          <button
            type="button"
            class="
              text-[#FFFFFF]
              hover:text-greyColor
              focus:outline-none focus:text-greyColor
            "
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <div :class="showMenu ? 'flex' : 'hidden'" class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0 font-Oswald">
        <router-link @click="toggleMenu" to="/" class="text-left text-[#ffffff]">Home</router-link> 
        <router-link @click="toggleMenu" to="/about" class="text-left text-[#ffffff]">About</router-link> 
        <router-link @click="toggleMenu" to="/source" class="text-left text-[#ffffff]">Sources</router-link> 
        <router-link @click="toggleMenu" to="/contactus" class="text-left text-[#ffffff]">Contact Us</router-link>
      </div>
  </nav>
</div>
<div>
  <img src=".\assets\Cbanner.png" class="w-full cover">
</div>
  <router-view/>
<footer class="bg-[#58C231] text-browncolor pb-2 pt-6 md:px-20">
  <div class="flex flex-col justify-evenly md:flex-row" >
    <div class="flex-none justify-start items-start w-30 pl-8 md:px-4 md:w-[250px]">
      <img src="./assets/Logo 150 (1).png" alt="Website logo" class="h-14 w-14 md:h-32 md:w-32">
    </div>
    <div class="flex-auto justify-start items-start w-fit pl-8 py-4 md:py-2 md:w-[450px]">
      <!-- <h1 class="text-avocadoColor md:pl-4 text-left justify-start items-start font-black text-lg md:text-xl font-Oswald">Find us on social media</h1>
      <ul class="flex flex-row justify-start items-start justify-around py-2 md:py-4 w-[300px] md:w-[350px]">
          <li><a href="https://www.facebook.com/profile.php?id=61552196137833&mibextid=2JQ9oc" target="_blank"><img src="./assets/facebook.png" class="w-8 h-8 md:w-10 md:h-10 rounded-lg"></a></li>
          <li><a href="https://www.instagram.com/example" target="_blank"><img src="./assets/insta.png" class="w-8 h-8 md:w-10 md:h-10 rounded-lg"></a></li>
          <li><a href="https://www.linkedin.com/company/ammich-fruits/" target="_blank"><img src="./assets/Linkedin.png" class="w-8 h-8 md:w-10 md:h-10 rounded-lg"></a></li>
          <li><a href="https://x.com/AmmichFruits?t=q2TI_f-Jvmv3OacH6YzR2w&s=09" target="_blank"><img src="./assets/xlogo.png" class="w-8 h-8 md:w-10 md:h-10 rounded-lg"></a></li>
          <li><a href="https://wa.me/251929419111" target="_blank"><img src="./assets/whatsapp.png" class="w-8 h-8 md:w-10 md:h-10 rounded-lg"></a></li>
      </ul> -->
    </div>
    <div class="flex flex-col justify-start items-start w-50 pl-8 md:px-2 py-2 md:py-2 md:w-[450px]">
      <h1 class="text-[#fff] text-left justify-start items-start font-black text-lg md:text-xl font-Oswald">Contact Us</h1>
      <!-- <p class="text-brownColor pl-2 py-2 font-bold font-Nunito text-base md:text-lg w-max">Whatsapp: <span class="text-brownColor font-Nunito font-normal text-sm md:text-base">+251929419111</span></p> -->
      <p class="text-[#fff] font-bold font-Nunito text-base md:text-lg w-max">Telephone: 
        <span class="text-white font-Nunito font-normal text-sm md:text-base">+251929419111</span><br>
        <span class="text-white font-Nunito font-normal pl-[88px] md:pl-[97px] text-sm md:text-base">+251911447890</span><br>
        <span class="text-white font-Nunito font-normal pl-[88px] md:pl-[97px] text-sm md:text-base">+251943073529</span></p>
      <p class="text-[#fff] font-bold font-Nunito text-base md:text-lg w-max">Email: 
        <a href="mailto:ammichcoffee@gmail.com" class="text-white underline underline-offset-2 font-Nunito font-normal text-sm md:text-base">ammichcoffee@gmail.com</a></p>

    </div>
  </div>
</footer>
<div class="bg-[#016435] w-full py-4">
    <h3 class="text-whiteshColor text-sm font-bold font-Oswald">AmMich Coffee ©2023. All Rights Reserved.</h3>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  setup() {
    let showMenu = ref(false);

    const toggleNav = () => {
      showMenu.value = !showMenu.value;
    };

    const toggleMenu = () => {
      showMenu.value = false;
    };

    return { showMenu, toggleNav, toggleMenu };
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #ffffff;
}

nav a.router-link-exact-active {
  color: #3E1404;
  font-weight: 900;
}
</style>
