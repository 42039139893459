<template>
  <div class="home">
   <h1 class="py-6 px-6 text-avocadoColor text-5xl font-bold font-Oswald">Taste of Ethiopia</h1>
   <div><span class="py-[1px] px-2 bg-brownColor">&nbsp;</span></div>
   <div class="py-6 px-8 md:px-20">
    <div>
    <p class="py-6 px-6 font-Playfair text-base md:text-l">
      Welcome to Ammich Coffee, where the rich history and vibrant culture of Ethiopia's coffee tradition come 
      alive in every bean we export. Nestled in the birthplace of coffee, Ethiopia, our company is dedicated to 
      bringing you the finest selection of Ethiopian coffee, known worldwide for its unique flavors and unmatched 
      quality.<br><br>
    </p>
    </div>
    <!--Our Produces Title-->
    <div> 
      <h1 class="py-6 px-6 text-avocadoColor text-4xl font-bold font-Oswald">Our Sources</h1>
      <div><span class="py-[1px] px-2 bg-brownColor">&nbsp;</span></div>
      <p class="py-6 px-6 font-Playfair text-base md:text-2xl">Sourced from <b>Direct Trade</b> with <b>Reliable Supply Chain</b></p>
      <!--Yiracheffe Section-->
      <div class="flex flex-col md:flex-row py-6  md:px-20"> 
        <div class="md:w-1/2 px-20 md:border-r-4 md:border-brownColor"> 
          <img src="../assets/G2 (1).png">
        </div>
        <div class="md:w-1/2 px-2 md:px-2 py-4 bg-whiteshColor">
          <h4 class="text-2xl text-brownColor md:m-4 pt-2 font-bold font-Nunito md:text-left">Yirgacheffe</h4> 
          <p class="text-base md:text-l pt-2 md:m-4 font-Playfair md:text-left">
            Known for its floral and fruity notes, Yirgacheffe coffee offers a delicate balance of sweet and 
            aromatic flavors, making it a favorite among coffee aficionados.
          </p>
        </div>
      </div>
      <!--Guji Section-->
      <div class="flex flex-col md:flex-row-reverse py-6  md:px-20"> 
        <div class="md:w-1/2 px-6 md:border-l-4 md:border-brownColor"> 
          <img src="../assets/GG.png">
        </div>
        <div class="md:w-1/2 px-2 md:px-2 py-4 bg-whiteshColor">
          <h4 class="text-2xl text-brownColor md:m-4 pt-2 font-bold font-Nunito md:text-left">Guji</h4> 
          <p class="text-base md:text-l pt-2 font-Playfair md:m-4 md:text-left">
            Hailing from the southern part of Ethiopia, Guji coffee is celebrated for its berry-like undertones 
            and vibrant acidity, offering a unique and refreshing coffee experience.
          </p>
        </div>
      </div>
      <!--Sidamo-->
      <div class="flex flex-col md:flex-row py-6  md:px-20"> 
        <div class="md:w-1/2 px-20 md:border-r-4 md:border-brownColor"> 
          <img src="../assets/JJ.png">
        </div>
        <div class="md:w-1/2 px-2 md:px-2 py-4 bg-whiteshColor">
          <h4 class="text-2xl  text-brownColor md:m-4 pt-2 font-bold font-Nunito md:text-left">Sidamo</h4> 
          <p class="text-base md:text-l pt-2 md:m-4 font-Playfair md:text-left">
            With its citrusy and floral notes, Sidamo coffee is a testament to the diverse climatic 
            conditions of the region, producing a light and enjoyable cup.
          </p>
        </div>
      </div>
      <!--Jimma Section-->
      <div class="flex flex-col md:flex-row-reverse py-6  md:px-20"> 
        <div class="md:w-1/2 px-6 md:border-l-4 md:border-brownColor"> 
          <img src="../assets/S1.png">
        </div>
        <div class="md:w-1/2 px-2 md:px-2 py-4 bg-whiteshColor">
          <h4 class="text-2xl text-brownColor md:m-4 pt-2 font-bold font-Nunito md:text-left">Jimma</h4> 
          <p class="text-base md:text-l pt-2 font-Playfair md:m-4 md:text-left">
            Renowned for its bold body and rich flavors, Jimma coffee provides a robust cup that resonates 
            with the essence of Ethiopia's coffee heritage.
          </p>
        </div>
      </div>
      <!--Nekemt-->
      <div class="flex flex-col md:flex-row py-6  md:px-20"> 
        <div class="md:w-1/2 px-20 md:border-r-4 md:border-brownColor"> 
          <img src="../assets/Nee.png">
        </div>
        <div class="md:w-1/2 px-2 md:px-2 py-4 bg-whiteshColor">
          <h4 class="text-2xl text-brownColor md:m-4 pt-2 font-bold font-Nunito md:text-left">Nekemt</h4> 
          <p class="text-base md:text-l pt-2 md:m-4 font-Playfair md:text-left">
            Offering a rare and distinct flavor profile, Nekemt coffee captivates with its subtle fruity 
            notes and smooth finish.
          </p>
        </div>
      </div>
      <!--Harar Section-->
      <div class="flex flex-col md:flex-row-reverse py-6  md:px-20"> 
        <div class="md:w-1/2 px-6 md:border-l-4 md:border-brownColor"> 
          <img src="../assets/H.png">
        </div>
        <div class="md:w-1/2 px-2 md:px-2 py-4 bg-whiteshColor">
          <h4 class="text-2xl text-brownColor md:m-4 pt-2 font-bold font-Nunito md:text-left">Harar</h4> 
          <p class="text-base md:text-l pt-2 font-Playfair md:m-4 md:text-left">
            Known for its winey and spicy attributes, Harar coffee is a dry-processed bean that delivers a strong 
            and exotic flavor, highly prized by coffee lovers.
          </p>
        </div>
      </div>
    </div>
    <!--Our Commitment to Quality and Integrity-->
    <div>
      <h1 class="py-6 px-6 text-avocadoColor text-4xl font-bold font-Oswald">Our Commitment to Quality and Integrity</h1>
      <div><span class="py-[1px] px-2 bg-brownColor">&nbsp;</span></div>
      <div class="flex flex-col md:flex-row md:px-20 py-6"> 
        <p class="py-2 pt-6 px-4 md:px-10 text-base md:text-l font-Playfair text-left md:w-7/12">
        Ammich Coffee stands by its commitment to quality and integrity. We acquire our coffee directly from farms, 
        ensuring that every bean is meticulously selected and harvested at its peak.
        <br><br>
        Through vertical integration, 
        we maintain control over the entire process, from farm to cup, guaranteeing the highest quality standards 
        and traceability. <br> <br> Our direct involvement with the local farming communities not only ensures the finest 
        coffee for our customers but also supports sustainable practices and the well-being of the farmers.</p>
        <img src="../assets/Hh.png" alt="" class=" h-80 md:px-8 px-4 py-2">
      </div>
    </div>
    <!--Why Us-->
    <div> 
  <h1 class="py-6 px-6 text-avocadoColor text-4xl font-bold font-Oswald">Why Choose Ammich Coffee?</h1>
  <div><span class="py-[1px] px-2 bg-brownColor">&nbsp;</span></div>
  <div class="flex flex-col justify-between w-full md:flex-row py-6 md:px-20 space-y-4 md:space-y-0 md:space-x-4">
    <div class="bg-[#EFE3D1] flex flex-col py-4 md:mb-0"> 
      <img src="../assets/W4.png" class="box-border h-[400px] w-full p-4">
      <h4 class="text-l md:text-xl text-brownColor px-4 pt-2 font-bold font-Nunito text-left">Unparalleled Quality Assurance</h4>
      <p class="text-left px-4 md:text-l text-base">We ensure that every bean sourced meets the highest standards of quality. 
        By conducting rigorous checks and leveraging our deep industry knowledge, we guarantee that our clients receive exceptional 
        coffee. This relentless focus on quality not only enhances the flavor profile of the coffee but also ensures consistency, 
        which is crucial for businesses looking to build a loyal customer base.</p>
    </div>
    <div class="bg-[#DEC59E] flex flex-col py-4 md:mb-0"> 
      <img src="../assets/W2.png" class="box-border h-[400px] w-full p-4">
      <h4 class="text-l md:text-xl text-brownColor pt-2 font-bold font-Nunito text-left px-4">Customized Sourcing Solutions</h4>
      <p class="text-left px-4 md:text-l text-base">Understanding that every business has unique needs and budget constraints, we offer 
        personalized sourcing solutions. Whether you're a small boutique café or a large retail chain, we tailor our services to match 
        your budget and preferences, bringing you offers that maximize value without compromising on quality. This flexibility allows 
        us to serve a wide range of clients, ensuring that you get the best possible coffee options within your financial parameters.</p>
    </div>
    <div class="bg-[#EDC78C] flex flex-col py-4 md:mb-0"> 
      <img src="../assets/W3.png" class="box-border h-[400px] w-full p-4">
      <h4 class="text-l md:text-xl text-brownColor px-4 pt-2 font-bold font-Nunito text-left">Transparent and Fair Pricing</h4>
      <p class="text-left px-4 md:text-l text-base">We stand out in the industry by not charging any commission fees, making our premium 
        coffee beans more accessible. This transparent pricing structure, combined with our commitment to bringing you offers based on 
        your budget, ensures that you can enjoy high-quality coffee at competitive prices. We believe in fostering long-term relationships 
        built on trust and fairness, making us a reliable partner in your coffee sourcing journey.</p>
    </div>
  </div>
</div>

    <!--Our Partners Marquee-->
    <div> 
      <h1 class="py-6 px-6 text-avocadoColor text-4xl font-bold font-Oswald">Our Partners</h1>
      <div><span class="py-[1px] px-2 bg-brownColor">&nbsp;</span></div>
      <p class="py-2 pt-6 px-4 md:px-10 text-base md:text-l font-Playfair">We collaborate with the best in the industry because we're dedicated to delivering unparalleled quality to our customers. Our partners share our commitment to excellence, 
        ensuring that every product and service we provide is backed by their expertise and innovation.</p>
      <div class="container py-6">
        <section class="customer-logos slider">
            <div class="slide"><img src="../assets/MoA.png"></div>
            <!-- <div class="slide"><img src="../assets/ATALogo.png"></div> -->
            <div class="slide"><img src="../assets/Airlines.png"></div>
            <div class="slide"><img src="../assets/esh.png"></div>
            <div class="slide"><img src="../assets/ECTA.png"></div>
            <div class="slide"><img src="../assets/ECX.png"></div>
            <!-- <div class="slide"><img src="../assets/Sid.png"></div> -->
            <div class="slide"><img src="../assets/Afca.png"></div>
            <div class="slide"><img src="../assets/Eca.png"></div>
        </section>
      </div>
    </div>
   </div>
  </div>
</template>

<style>
/* Slider */

.slick-slide {
    background: none;
    margin: 5px 20px;
    padding: 0px 45px;
}

.slick-slide img {
    width: 100%;
    height: max-content;
}

.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;
    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
</style>

<script>
import $ from 'jquery'; // Import jQuery
import 'slick-carousel/slick/slick.css'; // Import Slick CSS
import 'slick-carousel/slick/slick.js'; // Import Slick JS

export default {
  mounted() {
  this.$nextTick(() => {
    this.initSlickSlider();
  });
},
  methods: {
    initSlickSlider() {
      $('.customer-logos').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 1
            }
        }]
      });
    }
  }
};
</script>

